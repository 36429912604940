import { EditorSDK } from '@wix/platform-editor-sdk';
import * as routersWrapper from '../wrappers/routers';
import * as applicationState from '../applicationState';
import { log } from '../../utils/monitoring';
import * as menusWrapper from '../wrappers/menus';
import * as menusService from '../services/menus';
import * as routersService from '../services/routers';
import * as tpaWrapper from '../wrappers/tpa';
import { RouterPatterns } from '../../types/EditorAppModule';

async function logMissingRouters(editorSDK: EditorSDK) {
  const routers = await routersWrapper.getAll(editorSDK);

  if (routers.length !== 2) {
    const state = await applicationState.getApplicationComponents(editorSDK);
    const extra = {
      routers: JSON.stringify(routers),
      applicationState: JSON.stringify(state),
    };
    log('Missing routers when they should be apparent ', { extra });
  }
}

async function hasAllMenus(editorSDK: EditorSDK) {
  const menuIds = menusWrapper.getMenuIds();
  const menus = await Promise.all([
    menusWrapper.getMenuById({ editorSDK, menuId: menuIds.members }),
    menusWrapper.getMenuById({ editorSDK, menuId: menuIds.login }),
    menusWrapper.getMenuById({ editorSDK, menuId: menuIds.icons }),
  ]);

  return menus.filter(Boolean).length === 3;
}

async function removeRouterPattersWithMissingAppSection(editorSDK: EditorSDK) {
  const routers = await routersWrapper.getAll(editorSDK);
  let hasIncorrectPatterns = false;

  for (const router of routers) {
    const entries = Object.entries(((router.config?.patterns ?? {}) as unknown) as RouterPatterns);

    for (const [patternKey, patternConfig] of entries) {
      const appData = patternConfig.appData;
      if (!appData?.appDefinitionId || !appData?.appPageId) {
        continue;
      }

      const isInstalled = await tpaWrapper.isAppSectionInstalled({
        editorSDK,
        appDefinitionId: appData.appDefinitionId,
        sectionId: appData.appPageId,
      });

      if (!isInstalled) {
        log('MA-260 - Deleting incorrect router pattern', { extra: { router, patternKey } });
        await routersService.deleteRouterPatternByPatternKey({ editorSDK, router, patternKey });
        hasIncorrectPatterns = true;
      }
    }
  }

  return hasIncorrectPatterns;
}

export async function maybeFixBrokenMenuItems(editorSDK: EditorSDK) {
  try {
    await logMissingRouters(editorSDK);

    // If all menus aren't apparent, don't fix them - MA state is too broken already
    if (!(await hasAllMenus(editorSDK))) {
      return;
    }

    // MA-260 workaround
    const patternsWereRemoved = await removeRouterPattersWithMissingAppSection(editorSDK);

    if (patternsWereRemoved) {
      await menusService.cleanUpMenus({
        editorSDK,
        shouldDisableHorizontalMenuFixing: false,
        shouldDisableLoginMenuFixing: false,
      });
    } else {
      await menusService.maybeCleanUpMenus(editorSDK);
    }
  } catch (e) {
    log('An error was thrown while fixing a broken installation', { extra: e });
  }
}
